// @flow
import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

import { Dialog, Button } from './StyledComponents';

import { ReactComponent as APIcon } from './icons/APIcon.svg';
import BGImage from './icons/APBGImage.png';

type TTalkToUsDialog = {
  open: boolean,
  onClose: () => void,
};

const TalkToUsDialog: React$StatelessFunctionalComponent<TTalkToUsDialog> = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <Stack alignItems="center" sx={{ p: 3, maxWidth: 555, width: '100%' }}>
      <Box fontSize={59} display="flex" sx={{ my: 2 }}>
        <SvgIcon component={APIcon} inheritViewBox fontSize="inherit" />
      </Box>
      <Typography variant="h5" sx={{ my: 1.5 }}>
        Upgrade to access DOKKA MC
      </Typography>
      <Typography variant="subtitle1" textAlign="center" sx={{ my: 1.5 }}>
        Automate your entire accounts payable process with DOKKA. From invoice capture to approval workflows and journal
        entry creation, DOKKA MC automates every step so you can process vendor invoices in seconds.
      </Typography>
      <Box style={{ margin: '12px 36px' }}>
        <img src={BGImage} alt="AP" width="100%" />
      </Box>
      <Stack direction="row" sx={{ my: 1.5 }} spacing={1}>
        <Button variant="text" onClick={onClose}>
          No Thanks
        </Button>
        <Button sx={{ p: [0.75, 1.75] }} variant="contained">
          Talk to us
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);

export default TalkToUsDialog;
