// @flow

import { Map, List, Record, OrderedMap, type RecordFactory, type RecordOf } from 'immutable';
import type { СontactType, SigningsType } from 'domain/organization/types.js.flow';
import type { CreateCompanyType, CompaniesType as ConfigCompaniesType } from '../contracts';

type SignPermissions = RecordOf<{
  users: List<string>,
  roles: List<string>,
}>;

type SignParamsTokenType = {|
  password: string,
  name: string,
  permissions: ?SignPermissions,
|};

type RawSignParamsType = {|
  guid: string,
  token1: SignParamsTokenType,
  token2: SignParamsTokenType,
|};

export type SignParamsType = {|
  guid: string,
  token1: RecordOf<SignParamsTokenType>,
  token2: RecordOf<SignParamsTokenType>,
|};

export type ErpsType = {|
  QBO: boolean,
  QBD: boolean,
  XERO: boolean,
  ZOHO: boolean,
  SAGE_SA: boolean,
  SAGE: boolean,
  PRIORITY_PRO: boolean,
  PRIORITY_PRO_SQL: boolean,
  HASH: boolean,
  DEFAULT_ERP: boolean,
  FOX: boolean,
  SAP: boolean,
  NETSUITE: boolean,
|};

export type FeatureSetItemType = {
  value: boolean,
  display_name: string,
  state: 'hidden' | 'disabled' | 'enabled',
  help: null | string,
  show_hint: boolean,
};

export type FeatureSetType = Map<string, RecordOf<FeatureSetItemType>>;

export type CompanyType = {|
  checksum: string,
  cname: string,
  id: string,
  corresp: boolean,
  description: string,
  email: string,
  language: string,
  logo: string,
  pinned: boolean,
  'email.confidential': string,
  unprocessed: number,
  notifications: number,
  pendingApproval: number,
  signParams: RecordOf<SignParamsType>,
  generalParams: FeatureSetType,
  dateFormat: string,
  organizationId: string,
  isConnectedToErp: boolean,
  whatsappPhone: ?string,
|};

type RawCompanyType = {|
  checksum?: string,
  cname: string,
  id: string,
  corresp?: boolean,
  description?: string,
  email?: string,
  language?: string,
  logo?: string,
  pinned?: boolean,
  'email.confidential'?: string,
  unprocessed?: number,
  notifications?: number,
  pending_approval?: number,
  signParams?: RawSignParamsType,
  generalParams?: FeatureSetType,
  organizationId: string,
  is_connected_to_erp: boolean,
  whatsapp_phone: ?string,
|};

export type Company = RecordOf<CompanyType>;

export type ConfigurationCompany = {
  picture: string,
  name: string,
  city: string,
  email_box: string,
  confidential_email_box: string,
  country: string,
  contact: ?RecordOf<СontactType>,
  signings: ?RecordOf<SigningsType>,
  signingsCompanyId: ?string,
  featureSet: FeatureSetType,
  featureSetCompanyId: ?string,
  street: string,
  currency: string,
  vat_number: string,
  id: string,
  registration_number: string,
  whatsapp_phone: ?string,
};

export type ConfigurationCompanyListType = List<RecordOf<ConfigurationCompany>>;

export const SEARCH_TYPES = Object.freeze({
  byCompany: 'byCompany',
  byDocumentText: 'byDocumentText',
});

export type CompaniesType = {|
  list: Map<string, RecordOf<CompanyType>>,
  filter: string | Array<string>,
  searchText: string,
  searchType: $Values<typeof SEARCH_TYPES>,
  configurationCompany: RecordOf<ConfigurationCompany>,
  sort: string,
  refreshCompany: string,
  loaded: boolean,
|};

export type CompaniesStore = RecordOf<CompaniesType>;

export type NotificationsType = Map<string, number>;
type CompaniesListType = List<RecordOf<CompanyType>>;

const SignParamsTokenFactory: RecordFactory<SignParamsTokenType> = new Record({
  password: '',
  name: '',
  permissions: null,
});

const permissionsFactory = new Record({
  users: new List(),
  roles: new List(),
});

// eslint-disable-next-line max-len
export function permissionsAdapter({
  users,
  roles,
}: {
  users?: $ReadOnlyArray<string>,
  roles?: $ReadOnlyArray<string>,
}) {
  if (typeof users === 'undefined' && typeof roles === 'undefined') return null;
  return permissionsFactory({ users: List(users), roles: List(roles) });
}

function signParamsAdapter(sp, token: 'token1' | 'token2') {
  const p = sp[token] && sp[token].permissions;
  return SignParamsTokenFactory({
    ...sp[token],
    permissions: p ? permissionsAdapter(p) : null,
  });
}

export function signParamsFactory(signParams?: RawSignParamsType): RecordOf<SignParamsType> {
  const spf: RecordFactory<SignParamsType> = new Record({
    guid: '',
    token1: SignParamsTokenFactory(),
    token2: SignParamsTokenFactory(),
  });
  if (typeof signParams !== 'undefined') {
    const token1: RecordOf<SignParamsTokenType> = signParamsAdapter(signParams, 'token1');
    const token2: RecordOf<SignParamsTokenType> = signParamsAdapter(signParams, 'token2');
    return spf({ guid: signParams.guid, token1, token2 });
  }
  return spf();
}

export const CONNECTED_STATUSES = {
  connected: 'connected',
  inactive: 'inactive',
  disconnected: 'disconnected',
};

export const MANUALL_INTEGRATION_ERPS = Object.freeze({
  kashFlow: 'kashFlow',
  microsoft: 'microsoft',
});

export const ERP_NAMES = Object.freeze({
  acumatica: 'ACUMATICA',
  mdNavision: 'MICROSOFT_DYNAMICS_NAV',
  mdBC: 'MICROSOFT_DYNAMICS_BC',
  qbo: 'QBO',
  qbd: 'QBD',
  xero: 'XERO',
  zoho: 'ZOHO',
  sageSA: 'SAGE_SA',
  sage: 'SAGE',
  priorityPro: 'PRIORITY_PRO',
  priorityProSQL: 'PRIORITY_PRO_SQL',
  hash: 'HASH',
  fox: 'FOX',
  sap: 'SAP',
  defaultErp: 'DEFAULT_ERP',
  netsuite: 'NETSUITE',
});

export const ERP_TITLES = {
  [ERP_NAMES.acumatica]: 'Acumatica',
  [ERP_NAMES.mdNavision]: 'Microsoft Dynamics Navision',
  [ERP_NAMES.mdBC]: 'Microsoft Dynamics 365 Business Central',
  [ERP_NAMES.qbo]: 'QuickBooks Online',
  [ERP_NAMES.qbd]: 'QuickBooks Desktop',
  [ERP_NAMES.xero]: 'XERO',
  [ERP_NAMES.zoho]: 'Zoho Books',
  [ERP_NAMES.sageSA]: 'Sage Business Cloud (South Africa)',
  [ERP_NAMES.sage]: 'Sage Business Cloud (Global)',
  [ERP_NAMES.priorityPro]: 'Priority',
  [ERP_NAMES.priorityProSQL]: 'Priority SQL',
  [ERP_NAMES.hash]: 'H-ERP',
  [ERP_NAMES.fox]: 'Custom ERP',
  [ERP_NAMES.sap]: 'SAP Business One',
  [ERP_NAMES.netsuite]: 'Netsuite',
  [ERP_NAMES.defaultErp]: 'Default',
};

const featureSetErpFactory = new Record({
  [ERP_NAMES.acumatica]: false,
  [ERP_NAMES.mdNavision]: false,
  [ERP_NAMES.mdBC]: false,
  [ERP_NAMES.qbo]: false,
  [ERP_NAMES.qbd]: false,
  [ERP_NAMES.xero]: false,
  [ERP_NAMES.zoho]: false,
  [ERP_NAMES.sageSA]: false,
  [ERP_NAMES.sage]: false,
  [ERP_NAMES.priorityPro]: false,
  [ERP_NAMES.priorityProSQL]: false,
  [ERP_NAMES.hash]: false,
  [ERP_NAMES.fox]: false,
  [ERP_NAMES.sap]: false,
  [ERP_NAMES.netsuite]: false,
  [ERP_NAMES.defaultErp]: false,
});

const featureSetItemFactory = new Record({
  value: false,
  display_name: '',
  state: 'hidden',
  help: null,
  show_hint: false,
});

export const applicationsFactory = new Record({
  slack: {
    status: 'not_connected',
    workspace: '',
  },
});

export const featureSetFactory = (data: any = {}): FeatureSetType => {
  const { erp, ...rest } = data;
  const features = OrderedMap(rest).mapEntries(([K, V]) => [K, featureSetItemFactory(V)]);
  return features.set('erp', featureSetErpFactory(erp));
};

export function CompanyFactory(company?: RawCompanyType): RecordOf<CompanyType> {
  const cf: RecordFactory<CompanyType> = new Record({
    checksum: '',
    cname: '',
    id: '',
    organizationId: '',
    corresp: false,
    description: '',
    email: '',
    'email.confidential': '',
    language: '',
    logo: '',
    pinned: false,
    unprocessed: 0,
    notifications: 0,
    pendingApproval: 0,
    signParams: signParamsFactory(),
    generalParams: featureSetFactory(),
    dateFormat: 'DD/MM/YYYY',
    isConnectedToErp: false,
    whatsappPhone: null,
  });
  if (typeof company !== 'undefined') {
    const {
      signParams,
      generalParams,
      logo,
      is_connected_to_erp, // eslint-disable-line camelcase
      pending_approval, // eslint-disable-line camelcase
      whatsapp_phone, // eslint-disable-line camelcase
      ...rest
    } = company;
    const signParamsR = signParamsFactory(signParams);
    const generalParamsR = featureSetFactory(generalParams);
    return cf({
      ...rest,
      signParams: signParamsR,
      generalParams: generalParamsR,
      logo,
      isConnectedToErp: is_connected_to_erp,
      pendingApproval: pending_approval,
      whatsappPhone: whatsapp_phone,
    });
  }
  return cf();
}

// eslint-disable-next-line max-len
export const sortProp =
  (key: $Keys<CompanyType>) =>
  (map: RecordOf<CompanyType>): $Values<CompanyType> =>
    map.get(key);
export const sortByNumbersAndName = (bNot: number, aNot: number, bName: string, aName: string) => {
  if (bNot > aNot) return 1;
  if (bNot < aNot) return -1;
  if (bNot === aNot) return aName.localeCompare(bName);
  return 0;
};

// eslint-disable-next-line max-len
export function companiesAdapter(companies: $ReadOnlyArray<RawCompanyType>): Map<string, RecordOf<CompanyType>> {
  return companies.reduce((a, v: RawCompanyType) => a.set(v.id, CompanyFactory(v)), new Map());
}

// eslint-disable-next-line max-len
export function companiesFilter(
  companies: CompaniesListType,
  term: string | Array<string>,
  isSupport: boolean,
): CompaniesListType {
  return companies.filter((f) => {
    if (Array.isArray(term)) {
      return term.includes(f.get('id', ''));
    }
    return (
      ~f.get('cname', '').toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) ||
      (isSupport && ~f.get('id', '').indexOf(term))
    );
  });
}

// eslint-disable-next-line max-len
export function companiesSort(companies: CompaniesListType, sort: string, notifications: NotificationsType) {
  const list = companies
    .map((item) => item.set('notifications', notifications.get(item.id, 0)))
    .toList()
    .sortBy(sortProp('cname'));
  const filters = {
    favorites: (a, b) => b.pinned - a.pinned,
    unread: (a, b) => sortByNumbersAndName(b.notifications, a.notifications, b.cname, a.cname),
    new: (a, b) => sortByNumbersAndName(b.unprocessed, a.unprocessed, b.cname, a.cname),
    pendingApproval: (a, b) => sortByNumbersAndName(b.pendingApproval, a.pendingApproval, b.cname, a.cname),
  };
  return filters[sort] ? list.sort(filters[sort]) : list;
}

export const signerFactory = new Record({
  password: '',
  token_name: null,
  name: '',
});

const contactFactory = new Record({
  phone: '',
  first_name: '',
  last_name: '',
  email: '',
});

export const signingsFactory = (data: any = {}): SigningsType => {
  const signings = new Record({
    guid: '',
    tokens: new List(),
  });
  const record = signings(data);
  if (data.tokens) {
    return record.set('tokens', List(data.tokens.map((item) => signerFactory(item))));
  }
  return record;
};

export function configurationCompanyFactory(data: any): RecordOf<ConfigurationCompany> {
  const company = new Record({
    picture: '',
    name: '',
    city: '',
    country: '',
    currency: '',
    email_box: '',
    confidential_email_box: '',
    contact: contactFactory(),
    signings: signingsFactory(),
    signingsCompanyId: null,
    featureSet: featureSetFactory(),
    featureSetCompanyId: null,
    street: '',
    vat_number: '',
    id: null,
    registration_number: '',
    whatsapp_phone: null,
    applications: applicationsFactory(),
    amounts_representation: '',
    preparation_days: null,
  });
  if (typeof data !== 'undefined') {
    const { contact, signings, featureSet, ...rest } = data;
    return company({
      ...rest,
      contact: contactFactory(contact),
      signings: signingsFactory(signings),
      featureSet: featureSetFactory(featureSet || {}),
    });
  }
  return company();
}

// eslint-disable-next-line max-len
export function configurationCompaniesAdapter(data: ConfigCompaniesType): ConfigurationCompanyListType {
  return new List(data.map(configurationCompanyFactory));
}

export const CompaniesFactory: RecordFactory<CompaniesType> = new Record({
  list: new Map(),
  filter: '',
  searchText: '',
  searchType: SEARCH_TYPES.byCompany,
  configurationCompany: configurationCompanyFactory(),
  sort: 'favorites',
  refreshCompany: '',
  loaded: false,
});

export function createCompanyAdapter(data: CreateCompanyType): Map<string, RecordOf<CompanyType>> {
  const {
    id,
    name,
    picture,
    email_box, // eslint-disable-line camelcase
  } = data;

  return Map({
    [id]: CompanyFactory({
      cname: name,
      id,
      logo: picture,
      email: email_box,
    }),
  });
}

export function checkPermissions(p: SignPermissions, id: string, role: string): boolean {
  if (!p) return true;
  return p.users.some((u) => u === id) || p.roles.some((r) => r === role);
}

export const findCompanyById = (companiesList: $PropertyType<CompaniesType, 'list'>, id: string | number) =>
  companiesList.find((c) => c.id === id);

export const CompanyAdapter = (company: Company = CompanyFactory()) => {
  const { cname, logo, email, ...rest } = company;

  return configurationCompanyFactory({
    name: cname,
    picture: logo,
    email_box: email,
    ...rest,
  });
};
