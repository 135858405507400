// @flow
import { selector } from 'lib/selectors';
import { Map } from 'immutable';
import type { SettingsType, ERPSettings } from './settingsModel';
import type { ERPsType } from './types.js.flow';
import { type ConfigurationCompanyListType } from '../companies/helpers';

// wrong definition, takes global state
type SelectorType<T> = (s: SettingsType) => T;

const settings = (state): SettingsType => state.settings;
export const erpsSelector: SelectorType<ERPsType> = selector(settings, (s) => s.erps);
export const currentERPSettingsSelector: SelectorType<ERPSettings> = selector(settings, (s) => s.erpSettings);
export const indexesSyncTimeFieldSelector = selector(currentERPSettingsSelector, (s) =>
  s.find(({ type }) => type === 'indexes_sync_time'),
);
export const erpIndexesSyncDateSelector = selector(indexesSyncTimeFieldSelector, (indexesSyncTimeField) =>
  indexesSyncTimeField ? indexesSyncTimeField.get('indexes_update_date') : indexesSyncTimeField,
);
export const isSyncRunningSelector = selector(indexesSyncTimeFieldSelector, (indexesSyncTimeField) =>
  indexesSyncTimeField ? indexesSyncTimeField.get('is_sync_running') : false,
);
export const canErpSyncSelector: boolean = selector(
  indexesSyncTimeFieldSelector,
  (indexesSyncTimeField) => !!indexesSyncTimeField,
);
export const isLoadingERPSettingSelector: boolean = selector(settings, (s) => s.isLoadingERPSetting);
export const currentERPCompaniesSelector: SelectorType<ConfigurationCompanyListType> = selector(
  settings,
  (s) => s.erpCompanies,
);
export const currentERPSettingsInitialValuesSelector: SelectorType<Map<string, string>> = selector(
  settings,
  (s: SettingsType) => s.erpSettings.reduce((acc, setting) => acc.set(setting.name, setting.value), new Map()),
);
export const currentERPGroupedSettingsSelector: SelectorType<Map<string, ERPSettings>> = selector(
  settings,
  (s: SettingsType) => s.erpSettings.groupBy((record) => record.group),
);
export const isErpConnectedSelector: boolean = selector(erpsSelector, (erps: ERPsType) =>
  erps.some((erp) => erp.status === 'connected'),
);

export const currentConnectedERPselector = selector(erpsSelector, (erps: ERPsType) =>
  erps.reduce((a, v, k) => (v.status === 'connected' ? k : a), null),
);

export const currentConnectedERPCompanyName = selector(
  erpsSelector,
  currentConnectedERPselector,
  (erps: ERPsType, currentERP: any) => erps.getIn([currentERP, 'details', 'company_name']),
);

export const isRequiredErpConnectSelector = selector(
  erpsSelector,
  (erps) => !erps.some((v) => ['connected', 'connecting'].includes(v.get('status'))),
);
