// @flow
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { generatePath } from 'react-router-dom';

import { appsSelector } from 'domain/env';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import AppSelectorIcon from '@mui/icons-material/Apps';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import TalkToUsDialog from './components/TalkToUsDialog';
import { Menu, MenuItem, IconWrapper } from './StyledComponents';

import useToggle from 'hooks/useToggle';

import { ReactComponent as APIcon } from './icons/APIcon.svg';
import { ReactComponent as APIconSelected } from './icons/APIconSelected.svg';
import { ReactComponent as APIconInactive } from './icons/APIconInactive.svg';
import { ReactComponent as MCIcon } from './icons/MCIcon.svg';
import { ReactComponent as MCIconSelected } from './icons/MCIconSelected.svg';
import { ReactComponent as MCIconInactive } from './icons/MCIconInactive.svg';

import { alpha, useTheme } from '@mui/material';

import elements from 'components/elements';

const Apps = {
  AP: 'AP',
  MC: 'MC',
};

const mapStateToProps = (state) => ({
  apps: appsSelector(state),
});

const ApplicationDropdown = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { formatMessage } = useIntl();
  const [toggleDialog, onToggleDialog] = useToggle(false);
  const popupState = usePopupState({ variant: 'popover', popupId: 'AppsDropdown' });

  const { apps } = useSelector(mapStateToProps);

  const onOpenDialog = useCallback(() => {
    onToggleDialog();
  }, [onToggleDialog]);

  const menuItems = useMemo(() => {
    const activeItems = {
      [Apps.AP]: {
        selected: {
          icon: <SvgIcon component={APIconSelected} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={APIcon} inheritViewBox fontSize="inherit" />,
        },
      },
      [Apps.MC]: {
        selected: {
          icon: <SvgIcon component={MCIconSelected} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={MCIcon} inheritViewBox fontSize="inherit" />,
          onClick: () => {
            const appUrl = apps.getIn(['MC', 'appUrl']);
            if (appUrl) {
              // this simply opens url in new tab/window according to browser preferences
              Object.assign(document.createElement('a'), {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: appUrl,
              }).click();
            }
          },
        },
      },
    };
    const inActiveItems = {
      [Apps.AP]: {
        selected: {
          icon: <SvgIcon component={APIconInactive} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={APIconInactive} inheritViewBox fontSize="inherit" />,
        },
      },
      [Apps.MC]: {
        selected: {
          icon: <SvgIcon component={MCIconInactive} inheritViewBox fontSize="inherit" />,
        },
        unselected: {
          icon: <SvgIcon component={MCIconInactive} inheritViewBox fontSize="inherit" />,
          onClick: () => {
            onOpenDialog();
          },
        },
      },
    };
    return [
      {
        value: Apps.AP,
      },
      {
        value: Apps.MC,
      },
    ].map((i) => {
      const app = apps.get(i.value);
      const items = app?.active ? activeItems : inActiveItems;
      const selectedState = i.value === Apps.AP ? 'selected' : 'unselected';
      return { ...i, ...items[i.value][selectedState], label: app?.appName };
    });
  }, [apps, onOpenDialog]);

  return (
    <>
      <IconButton
        size="small"
        sx={{
          boxShadow: `0 0 0 1px ${popupState.isOpen ? palette.primary.main : palette.grey[400]} inset`,
          backgroundColor: `${popupState.isOpen ? alpha(palette.primary.main, 0.3) : 'transparent'}`,
          '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.3),
          },
        }}
        {...bindTrigger(popupState)}
        data-element={elements.header.appSelector.container}
      >
        <AppSelectorIcon color="primary" />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} onClick={item.onClick} data-element={elements.header.appSelector.menuItem}>
            <Stack>
              <IconWrapper>{item.icon}</IconWrapper>
              <Typography variant="body1">{item.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      <TalkToUsDialog open={toggleDialog} onClose={onToggleDialog} />
    </>
  );
};

export default ApplicationDropdown;
