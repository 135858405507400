// @flow
import { List, type RecordOf, type List as ListOf, Map, Record } from 'immutable';
import { chatUnreadThreadSelector } from 'domain/chat/chatSelector';
import type { DocumentsStore } from 'domain/documents/documentsModel';
import {
  currentCompanySelector,
  documentCountSelector,
  savedGridRowsCountSelector,
  unreadRequestsSelector,
} from 'domain/documents/documentSelector';
import { selector } from 'lib/selectors';
import { isDokkaSupportSelector, userGUIDSelector, roleSelector, workSpaceTypeSelector } from 'domain/env/envSelector';
import type { SigningType } from 'domain/organization/types.js.flow';
import type { UnreadRequeestDocs } from 'domain/documents/types.js.flow';
import {
  companiesFilter,
  companiesSort,
  checkPermissions,
  type ErpsType as AvailableErpsType,
  type FeatureSetItemType,
} from './helpers';
import { CompanyFactory } from 'domain/companies';
import { allowedExportFormatsForGrid } from 'domain/documents/helpers';

import type { CompanyType, CompaniesStore, ConfigurationCompany, FeatureSetType } from './helpers';
import type { DokkaStore } from '../types.js.flow';
import type { TWorkspaceType } from 'domain/env/types.js.flow';
import { AMOUNT_REPRESENTATION_VALUES } from 'pages/common/forms/financial';

type SelectorType<T> = (s: DokkaStore) => T;
export type CompaniesList = List<RecordOf<CompanyType>>;
export type CompaniesById = Map<string, RecordOf<CompanyType>>;

const companies: CompaniesStore = (state: DokkaStore) => state.companies;
const documents: DocumentsStore = (state: DokkaStore) => state.documents;
// eslint-disable-next-line max-len
const currentOrganizationId = (state: DokkaStore): ?string => state.organization.currentOrganization;
export const searchTermSelector: SelectorType<string> = selector(companies, (c) => c.filter);
export const searchTextSelector: SelectorType<string> = selector(companies, (c) => c.searchText);
export const searchTypeSelector: SelectorType<string> = selector(companies, (c) => c.searchType);
export const searchSortSelector: SelectorType<string> = selector(companies, (c) => c.sort);
// eslint-disable-next-line max-len
export const refreshCompanySelector: SelectorType<string> = selector(companies, (c) => c.refreshCompany);
export const configurationCompanySelector: SelectorType<RecordOf<ConfigurationCompany>> = selector(
  companies,
  (c) => c.configurationCompany,
);
export const companyIdSelector: SelectorType<string> = selector(configurationCompanySelector, (c) => c.id);
export const shouldHighlightAmountSelector: SelectorType<boolean> = selector(
  configurationCompanySelector,
  (c) => c.amounts_representation === AMOUNT_REPRESENTATION_VALUES.HIGHLIGHTED,
);
export const companySigningsSelector: SelectorType<ListOf<SigningType>> = selector(
  configurationCompanySelector,
  (c) => c.signings,
);
export const companyApplicationsSelector: SelectorType<ListOf<SigningType>> = selector(
  configurationCompanySelector,
  (c) => c.applications,
);
export const companyFeatureSetSelector: SelectorType<RecordOf<FeatureSetType>> = selector(
  configurationCompanySelector,
  (c) => {
    const features = {};
    Object.entries(c.featureSet.toJS()).forEach(([key, data]) => {
      if (key === 'erp') {
        features[key] = data;
      } else {
        features[key] = data.value;
      }
    });
    // this is sort of dynamic record. Its keys are not predefined
    // this makes it possible to use Record in here
    const result = new Record(features);
    return result();
  },
);

export const companyRegularFeatureSetSelector: SelectorType<RecordOf<FeatureSetType>> = selector(
  configurationCompanySelector,
  // transitioning Map to Record in order to use Record field access api
  // fields are not predefined so this is sort of dynamic record to maintain Record API only
  (c) => new Record(c.featureSet.toJS())(),
);

export const companyAdvancedFeatureSetSelector: SelectorType<Array<{ key: string, value: FeatureSetItemType }>> =
  selector(configurationCompanySelector, (c) =>
    Object.entries(c.featureSet.toJS()).filter(
      ([key, { state }]) => key !== 'erp' && key !== 'fin' && state !== 'hidden',
    ),
  );

export const companyFeatureSetCompanyIdSelector: SelectorType<RecordOf<FeatureSetType>> = selector(
  configurationCompanySelector,
  (c) => c.featureSetCompanyId,
);

export const companySigningsCompanyIdSelector: SelectorType<RecordOf<FeatureSetType>> = selector(
  configurationCompanySelector,
  (c) => c.signingsCompanyId,
);

export const companyApprovalsFeatureEnabledSelector: SelectorType<boolean> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.approvals,
);
export const companyApprovalsFormFeatureEnabledSelector: SelectorType<boolean> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.approvals_form,
);
export const companyApprovalsStampFeatureEnabledSelector: SelectorType<boolean> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.approval_stamp,
);
export const companyPaidUnpaidFeatureEnabledSelector: SelectorType<boolean> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.paid_unpaid,
);
export const companyEnableClientChatFeatureSelector: SelectorType<boolean> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.enable_client_chat,
);

export const twoWayMatchingFeatureValueSelector: SelectorType<FeatureSetItemType> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.two_ways_matching,
);

export const companiesByIdSelector: SelectorType<CompaniesById> = selector(companies, (c) => c.list);

export const loadedSelector: SelectorType<boolean> = selector(companies, (c) => c.loaded);

export const companiesNameIdsSelector = selector(companiesByIdSelector, (c) =>
  c.reduce((r, { id, cname, isConnectedToErp }) => (isConnectedToErp ? [...r, { value: id, label: cname }] : r), []),
);
export const companyNameSelector = (id: string): SelectorType<RecordOf<CompanyType>> =>
  selector(companiesByIdSelector, (c) => c.get(id));

export const getCurrentCompanyData = selector(
  companiesByIdSelector,
  currentCompanySelector,
  (allCompanies, currentCompanyId) => allCompanies.get(currentCompanyId),
);

export const currentCompanyNameSelector = selector(companiesByIdSelector, currentCompanySelector, (list, id) =>
  list.get(id, CompanyFactory()).get('cname'),
);

export const currentCompanyDateFormatSelector = selector(companiesByIdSelector, currentCompanySelector, (list, id) =>
  list.get(id, CompanyFactory()).get('dateFormat'),
);

export const companyNotificationsSelector: SelectorType<Map<string, number>> = selector(chatUnreadThreadSelector, (t) =>
  t.reduce((a, v) => a.update(v.companyId, (u) => (u || 0) + v.unreadMessageCount), new Map()),
);

export const organizationNotificationsSelector: SelectorType<Map<string, number>> = selector(
  companyNotificationsSelector,
  companiesByIdSelector,
  (notifications: Map<string, number>, companyList: CompaniesById) =>
    notifications.reduce((a, counter, companyId) => {
      const { organizationId } = companyList.get(companyId, {});
      if (typeof organizationId === 'undefined') {
        return a;
      }
      return a.update(organizationId, (u) => (u || 0) + counter);
    }, new Map()),
);

// eslint-disable-next-line max-len
export const organizationNotificationsAndUnreadRequestsSelector: SelectorType<Map<string, number>> = selector(
  organizationNotificationsSelector,
  unreadRequestsSelector,
  companiesByIdSelector,
  (notifications: Map<string, number>, requests: UnreadRequeestDocs, companyList: CompaniesById) =>
    requests.reduce((a, request) => {
      const { organizationId } = companyList.get(request.companyId, {});
      if (typeof organizationId === 'undefined') {
        return a;
      }
      return a.update(organizationId, (u) => (u || 0) + 1);
    }, notifications),
);

export const companiesListSelector: SelectorType<CompaniesList> = selector(
  companiesByIdSelector,
  searchSortSelector,
  companyNotificationsSelector,
  companiesSort,
);

export const companiesSelector: SelectorType<CompaniesList> = selector(
  companiesListSelector,
  searchTermSelector,
  isDokkaSupportSelector,
  companiesFilter,
);

export const companiesByOrganizationSelector: SelectorType<CompaniesList> = selector(
  companiesSelector,
  currentOrganizationId,
  (companyList, currentOrganization) =>
    currentOrganization === null
      ? companyList
      : companyList.filter((company) => company.organizationId === currentOrganization),
);

export const allCompaniesByOrganizationSelector: SelectorType<CompaniesList> = selector(
  companiesListSelector,
  currentOrganizationId,
  (companyList, currentOrganization) =>
    currentOrganization === null
      ? companyList
      : companyList.filter((company) => company.organizationId === currentOrganization),
);

export const companyMessagesCountSelector: SelectorType<Map<string, number>> = selector(
  chatUnreadThreadSelector,
  documents,
  (c, d) => c.reduce((A, V) => (V.companyId === d.companyId ? A + V.unreadMessageCount : A), 0),
);

export const currentCompanyUnreadRequestsSelector: SelectorType<Map<string, number>> = selector(
  currentCompanySelector,
  unreadRequestsSelector,
  (companyId, unreadRequests) => unreadRequests.filter((r) => r.companyId === companyId),
);

export const currentCompanyUnreadRequestsCountSelector: SelectorType<Map<string, number>> = selector(
  currentCompanyUnreadRequestsSelector,
  (unreadRequests) => unreadRequests.size,
);

export const doesCurrentCompanySupportGeneralDocsOnlySelector: SelectorType<boolean> = selector(
  documents,
  companiesByIdSelector,
  (d: { companyId: string }, c: CompaniesById) => !c.getIn([d.companyId, 'generalParams', 'fin', 'value'], false),
);

export const isSignAvailableSelector = selector(
  userGUIDSelector,
  roleSelector,
  currentCompanySelector,
  companiesByIdSelector,
  (id, role, companyId, list) => {
    const sp = list.getIn([companyId, 'signParams']);
    return Map({
      token1: checkPermissions(sp.getIn(['token1', 'permissions']), id, role),
      token2: checkPermissions(sp.getIn(['token2', 'permissions']), id, role),
    });
  },
);

export const availableErpsForCompanySelector: SelectorType<AvailableErpsType> = selector(
  companyFeatureSetSelector,
  (featureSet: FeatureSetType) => featureSet.erp,
);

export const documentWorkSpaceTypeSelector: SelectorType<TWorkspaceType> = selector(
  workSpaceTypeSelector,
  currentCompanySelector,
  (ws: Map<string, TWorkspaceType>, companyId: string) => ws.get(companyId) || 'tile',
);

export const isGridWorkSpaceSelector: SelectorType<boolean> = selector(
  documentWorkSpaceTypeSelector,
  (wsType) => wsType === 'grid',
);

export const exportFormatsSelector = selector(documents, isGridWorkSpaceSelector, (d, isGridView) => {
  const allFormats = d.exportFormats;
  return isGridView ? allFormats.filter((v, k) => allowedExportFormatsForGrid.includes(k)) : allFormats;
});

export const companyDocumentsCountByFilterSelector: SelectorType<boolean> = selector(
  isGridWorkSpaceSelector,
  savedGridRowsCountSelector,
  documentCountSelector,
  (isGrid, gridCount, documentsCount) => (isGrid ? gridCount : documentsCount),
);
